import { z } from 'zod'
import {
  DemandDrivers,
  TokenDistributionPreview,
  TokenEmissionPreview,
} from './modeling.types'
import { ProjectDetails } from './valuation.types'
import { GroupUnlockFrequencyEnum } from '@forgd/supabase'

export const EmissionsDetails = z.object({
  name: z.string(),
  allocationPercentage: z.number(),
  unlockType: z.string(),
  unlockAtTGE: z.number(),
  unlockAtInitialCliff: z.number(),
  lockupDurationInMonths: z.number(),
  emissionDuration: z.number(),
  impliedFDV: z.number(),
  unlockFrequency: z
    .nativeEnum(GroupUnlockFrequencyEnum)
    .nullable()
    .default(GroupUnlockFrequencyEnum.Monthly),
})
export type EmissionsDetails = z.infer<typeof EmissionsDetails>

const kpiValueResponse = z.object({
  label: z.string(),
  value: z.number(),
})

const listOfKPIs = z.object({
  heading: z.string(),
  values: z.array(kpiValueResponse),
})

export const LaunchAndGrowthKPIsResponse = z.object({
  supply_in_circulation: listOfKPIs,
  avg_annual_inflation: listOfKPIs,
  usd_tokens_introduced: listOfKPIs,
  incremental_demand_required: listOfKPIs,
})

export type LaunchAndGrowthKPIs = z.infer<typeof LaunchAndGrowthKPIsResponse>

export const PublicPageResponse = z.union([
  z.object({
    maxTokenSupply: z.number(),
    distribution: TokenDistributionPreview,
    emissions: TokenEmissionPreview.nullable(),
    emissionsDetails: z.array(EmissionsDetails),
    monthlyIncrease: z.array(
      z.object({
        mon_supply_growth: z.number(),
        month: z.number(),
      }),
    ),
    launchAndGrowthKPIs: LaunchAndGrowthKPIsResponse,
    demandDriverValues: DemandDrivers,
    estimatedMonthlyDemand: z.array(
      z.object({
        demand_drivers: z.record(z.string(), z.number()),
        months_post_tge: z.number(),
      }),
    ),
    summary: ProjectDetails.nullable(),
  }),
  z.object({ publicPageUrl: z.string() }),
])
export type PublicPageResponse = z.infer<typeof PublicPageResponse>
